import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="digitalocean"
    name="DigitalOcean Spaces"
    shortDescription="We love DigitalOcean for its simplicity, leverage it for your backups."
    website={"https://www.digitalocean.com/products/spaces"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="digitalocean-ico.png"
    metaTitle={meta.title.replace('##provider##', 'DigitalOcean Spaces')}
    metaDescription={meta.description.replace('##provider##', 'DigitalOcean Spaces')}
  >
    <IntegrationStorageContent
      keyRef="digitalocean"
      service="DigitalOcean Spaces"
      provider="DigitalOcean"
      learnTags={['digitalocean']}
    />
  </IntegrationTemplate>
)

export default ContentPage
